import { useState, useRef, useEffect } from 'react';
import { FaCheck } from "react-icons/fa";
import { IoStarSharp } from "react-icons/io5";

// 1) Import Swiper and required modules
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';

// 2) Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

// import './App.css';
// import logo from './logo.svg'; // if you still need the logo, else remove

function Form() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [assesmentType, setAssesmentType] = useState(null);
  const [buildingType, setBuildingType] = useState(null);
  const [breeamRating, setBreeamRating] = useState(null);
  const [buildingSize, setBuildingSize] = useState(null);
  const [scopeOfWork, setScopeOfWork] = useState(null);
  const [quote, setQuote] = useState(null);
  const [selectedSize, setSelectedSize] = useState(null);
  let json = require('../../data/assesmentDurations.json');
  // console.log(json)

  // Ref to store Swiper instance
  const swiperRef = useRef(null);

  // Function to go to the next slide
  const handleNext = () => {
    if (swiperRef.current) {
      swiperRef.current.slideNext();
    }
  };

  useEffect(() => {
    handleNext();
}, [assesmentType, buildingType, breeamRating, scopeOfWork]);

const resetInputs = () => {
  setAssesmentType(null)
  setBuildingType(null)
  setBreeamRating(null)
  setBuildingSize(null)
  setScopeOfWork(null)
}

  // const handleAssesmentType = () => {
  //   if (swiperRef.current) {
  //     swiperRef.curr
  //   }
  // }

  const calculateTotal = () => {
    const hoursPerDay = 8
    let result = json.filter(obj => {
      return (
        obj['area'] === buildingSize && 
        obj['buildingType'] === scopeOfWork &&
        obj['rating'] === breeamRating 
      )
    })
    let totalPrice = 0;
    if (assesmentType == "pre") {
      const hourlyWage = 80 // £80 an hour
      totalPrice = result[0].days * hourlyWage * hoursPerDay;
    } else {
      const hourlyWage = 70 // £80 an hour
      result.forEach(obj => {
        totalPrice += (obj.days*hourlyWage * hoursPerDay);
      })
    }
    console.log(buildingSize+scopeOfWork+breeamRating)
    console.log(result)
    console.log(totalPrice)
    setQuote(totalPrice)
  }

  const handleSizeConfirm = () => {
    // Double-check if user selected something valid
    if (buildingSize == null) {
      alert('Please select a building size');
      return;
    }

    // Then move to the next slide
    handleNext();
  };


  return (
    <div className="App flex flex-col gap-5 p-8 items-center my-[100px] ">
      
      {/* Main Heading */}
      <h1 className='text-[50px] font-medium'>Starting at £12,000</h1>
      
      {/* Feature List */}
      <ul className='md:text-[20px] text-center w-max font-light m-auto'>
        <li className='flex items-center gap-2'><FaCheck/> BREEAM Advisory Professionals</li>
        <li className='flex items-center gap-2'><FaCheck/> BREEAM Pre-Assessments</li>
        <li className='flex items-center gap-2'><FaCheck/> BREEAM Full Assesment and Certification</li>
      </ul>

      {/* Button to open modal */}
      <button 
        className='w-max m-auto bg-green/90 rounded-full text-white text-[35px] font-medium px-5 py-2 transition ease-in-out hover:scale-110'
        onClick={() => setIsModalOpen(true)}
      >
        Get Quote Now
      </button>

      {/* Modal */}
      {isModalOpen && (
        <div 
          className="
            fixed inset-0 
            z-50 
            flex 
            items-center 
            justify-center 
            bg-black/50
          "
        >
          <div 
            className="
              relative 
              bg-white 
              w-min 
              max-h-[90vh] 
              overflow-y-auto 
              rounded-lg 
              p-5
            "
          >
            {/* Close button */}
            <button 
              className='absolute top-3 right-3 text-xl font-bold' 
              onClick={() => {setIsModalOpen(false); resetInputs()}}
            >
              &times;
            </button>

            {/* Swiper Form */}
            <Swiper
              // Attach the swiper instance to swiperRef
              onBeforeInit={(swiper) => {
                swiperRef.current = swiper;
              }}
              modules={[Pagination]}
              pagination={{ clickable: false }}
              spaceBetween={50}
              slidesPerView={1}
            >
              {/* Slide 1 */}
              <SwiperSlide>
                <div className="h-72 flex flex-col justify-center items-center gap-10">
                  <h2 className="text-2xl font-semibold">Assesment Type?</h2>
                  {/* <input
                    type="text"
                    placeholder="Enter your name..."
                    className="border p-2 w-3/4"
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') handleNext();
                    }}
                  /> */}
                  <div className='flex gap-5'>
                    <button className='
                    bg-green-500
                    w-max m-auto rounded-xl text-white text-[28px] font-medium px-5 py-2 transition ease-in-out hover:scale-110'
                    onClick={() => setAssesmentType('full')}
                    >
                      Full Assesment</button>
                      <button className='
                    bg-green-800
                    w-max m-auto rounded-xl text-white text-[28px] font-medium px-5 py-2 transition ease-in-out hover:scale-110'
                    onClick={() => setAssesmentType('pre')}

                    >
                      Pre Assesment</button>

                  </div>
                  {/* <button
                    className="bg-blue-500 text-white px-4 py-2 rounded"
                    onClick={handleNext}
                  >
                    Next
                  </button> */}
                </div>
              </SwiperSlide>

              {/* Slide 2 */}
              <SwiperSlide>
                <div className="h-72 flex flex-col justify-center items-center gap-5 ">
                  <h2 className="text-2xl font-semibold">What size is the building?</h2>
                  {/* <p>What size is the building?</p> */}
                  <form className='flex gap-10 flex-row justify-center text-[20px]'>
                    <span className='flex flex-col'>
                      <input type='radio' name='size'
                        onChange={() => setBuildingSize("Simple Building")}
                      /> 
                      <label >Simple Building</label>
                    </span>
                    <span className='flex flex-col'>
                      <input type='radio' name='size'
                        onChange={() => setBuildingSize("< 500m²")}
                      />
                      <label>{"< 500m²"}</label>
                    </span>
                    <span className='flex flex-col'>
                      <input type='radio' name='size'
                        onChange={() => setBuildingSize('500m² - 5000m²')}
                      />
                      <label>{'500m² - 5000m²'}</label>
                    </span>
                    <span className='flex flex-col'>
                      <input type='radio' name='size'
                        onChange={() => setBuildingSize('5000m² - 10,000m²')}
                      />
                      <label>{'5000m² - 10,000m²'}</label>
                    </span>
                    <span className='flex flex-col'>
                      <input type='radio' name='size'
                        onChange={() => setBuildingSize('> 10,000m²')}
                      />
                      <label>{'> 10,000m²'}</label>
                    </span>
                  </form>

                  <button
                    className="bg-green/80 text-white text-[25px] px-4 py-2 rounded"
                    onClick={() => handleSizeConfirm()}
                  >
                    Confirm
                  </button>
                </div>
              </SwiperSlide>

              {/* Slide 3 */}
              <SwiperSlide>
                <div className="h-72 flex flex-col justify-center items-center gap-5">
                  <h2 className="text-2xl font-semibold">Scope of Work</h2>
                  <div className='flex gap-5'>
                    <button className='
                    bg-green/100
                    w-max m-auto rounded-xl text-white text-[28px] font-medium px-5 py-2 transition ease-in-out hover:scale-110'
                    onClick={() => setScopeOfWork('fullyFitted')}
                    >
                      Fully Fitted</button>
                      <button className='
                    bg-green/90
                    w-max m-auto rounded-xl text-white text-[28px] font-medium px-5 py-2 transition ease-in-out hover:scale-110'
                    onClick={() => setScopeOfWork('shell/shell&core')}

                    >
                      Shell & Core</button>
                      <button className='
                    bg-green/80
                    w-max m-auto rounded-xl text-white text-[28px] font-medium px-5 py-2 transition ease-in-out hover:scale-110'
                    onClick={() => setScopeOfWork('shell/shell&core')}

                    >
                      Shell Only</button>

                  </div>
                </div>
              </SwiperSlide>

              {/* Slide 4 */}
              <SwiperSlide>
                <div className="h-72 flex flex-col justify-center items-center gap-5">
                  <h2 className="text-2xl font-semibold">Desired BREEAM Rating</h2>
                  <div className='flex gap-5'>

                  <button className='
                    bg-green/70
                    w-max m-auto rounded-xl text-white text-[28px] font-medium px-5 py-2 transition ease-in-out hover:scale-110'
                    onClick={() => setBreeamRating('good')}
                    >
                                            <span className='flex justify-centre'>
                      <IoStarSharp /><IoStarSharp /><IoStarSharp /><IoStarSharp />
                      </span>
                      Good</button>
                    <button className='
                    bg-green/80
                    w-max m-auto rounded-xl text-white text-[28px] font-medium px-5 py-2 transition ease-in-out hover:scale-110'
                    onClick={() => setBreeamRating('verygood')}
                    >
                                            <span className='flex'>
                      <IoStarSharp /><IoStarSharp /><IoStarSharp /><IoStarSharp />
                      </span>
                      Very Good</button>
                      <button className='
                    bg-green/90
                    w-max m-auto rounded-xl text-white text-[28px] font-medium px-5 py-2 transition ease-in-out hover:scale-110'
                    onClick={() => setBreeamRating('excellent')}

                    >
                                            <span className='flex'>
                      <IoStarSharp /><IoStarSharp /><IoStarSharp /><IoStarSharp /><IoStarSharp />
                      </span>
                      Excellent</button>
                      <button className='
                    bg-green/100
                    w-max m-auto rounded-xl text-white text-[28px] font-medium px-5 py-2 transition ease-in-out hover:scale-110'
                    onClick={() => setBreeamRating('outstanding')}

                    >
                      <span className='flex'>
                      <IoStarSharp /><IoStarSharp /><IoStarSharp /><IoStarSharp /><IoStarSharp /><IoStarSharp />
                      </span>
                      Outstanding
                      </button>

                  </div>
                </div>
              </SwiperSlide>

              {/* Final Slide */}
              <SwiperSlide>
                <div className="h-72 flex flex-col justify-center items-center gap-5">
                  <h2 className="text-2xl font-semibold">All Done!</h2>
                  <p>Click below to submit your information.</p>
                  <button
                    onClick={() => {calculateTotal(); alert('Form submitted!'); handleNext()}}
                    className="bg-green/85 text-[25px] text-white px-4 py-2 rounded"
                  >
                    Get Quote
                  </button>
                </div>
              </SwiperSlide>


            <SwiperSlide>
                <div className="h-72 flex flex-col justify-center items-center gap-5">
                  {/* <h2 className="text-2xl font-semibold">All Done!</h2> */}
                  {/* <p>Click below to submit your information.</p> */}
                  <h3 className="text-[60px] font-semibold">£{quote}</h3>
                  <button
                    className="bg-green-500 text-white px-4 py-2 rounded hover:cursor-pointer transition ease-in-out hover:scale-110"
                  >Get Certified</button>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      )}
    </div>
  );
}

export default Form;
