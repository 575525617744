import React from 'react';
import './Landing.css'

export default function Landing({onNavClick}) {

    const handleClick = () => {
        onNavClick('clients')
    }
    return(
        <main className="story-section px-5 md:py-[80xp] flex items-center w-full md:px-[100px] pt-[200px] bg-gradient-to-r h-[100vh] from-cyan-500 to-blue-500" id='landing'>
            <img src='/images/websiteBg.jpg' className='object-cover h-[100vh] z-20 absolute top-0 w-full left-0'/>
            <div className="z-50 relative" id="sectionA">
                <div class="flex flex-col md:flex-row items-center justify-center md:justify-between">
                    <div class="title-desc text-center md:text-left  md:pr-8">
                        <h1 class="text-3xl md:text-4xl lg:text-5xl font-bold mb-4">Creating a clean, liveable universe for all</h1>
                        <p class="text-base md:text-lg lg:text-2xl mb-4">
                            "Nile Villa is a sustainability consultancy specialising in <b>BREEAM certification</b>, energy consultancy, and eco-conscious solutions, guiding the UK’s construction and planning sectors towards <b>Net Zero</b> with responsible design and reduced environmental impact."
                        </p>
                        <button onClick={handleClick} class="w-max cursor-pointer bg-black text-white m-auto md:m-0 py-4 px-6 rounded  text-[25px] md:text-[30px]" id="learnMoreBtn" onclick="scrollDown()">Learn More</button>
                    </div>
                    {/* <img src="/images/netzero.png" id="graph" class="md:w-1/2"/> */}
                </div>
            </div>
        </main>
    )
}