import React from "react";
// import './Projects.css'

import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import { MdOutlineKeyboardDoubleArrowRight } from "react-icons/md";
import { FaArrowLeftLong, FaArrowRightLong } from "react-icons/fa6";

import { FaUser } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";
import { SlCalender } from "react-icons/sl";

import "swiper/css";
import "swiper/css/navigation";
import { Autoplay } from "swiper/modules";
import ProjectSwiper from "./ProjectSwiper";

export default function Projects() {

    const playerContent = [
        {
          img: "/images/proj1_unitb.png",
          title: "Unity Connect Unit B ",
          client: "Waystone Hargreaves Land LLP",
          location: "Doncaster, South Yorkshire",
          date: "December 2023",
          rating: "Very Good",
          area: 2645,
          type: "Industrial",
          scope: "Fully Fitted",
          content:
          "The development proposal includes the construction of a new 'Shell & Core' Industrial/Warehouse unit with ancillary offices, external yard, car park and service access."
          
        },
        {
            img: "/images/proj2_unit2.png",
            title: "White Hart Lane Works ",
            client: "LaSalle Investment Management",
            location: "North London",
            date: "October 2021",
            rating: "Excellent",
            area: 2677,
            type: "Industrial",
            scope: "Shell & Core",
          content:
          "The project is for the replacement of an industrial unit within the White Hart Lane Park that was damaged following a fire in November 2019. The development is a ‘Shell and Core’ - Warehouse with associated offices, external delivery yards and car-parking."        },
        {
            img: "/images/proj3_heathlands.png",
            title: "Heathlands care home",
            client: "Bracknell Forest Council",
            location: "Crossfell, Bracknell",
            date: "November 2021",
            rating: "Excellent",
            area: 2677,
            type: "Residential",
            scope: "Fully Fitted",
          content:
          "The construction of 66 bed Care Home for Dementia including an intermediate unit (20 beds) in Bracknell."                                                                                           },
      {
            img: "/images/proj4_bingley.png",
            title: "Bingley Street ",
            client: "Gregory Projects",
            location: "Leeds",
            date: "2020",
            rating: "Excellent",
            area: 2677,
            type: "Industrial",
            scope: "Fully Fitted",
          content: 
"To construct new 407 bed living student accommodation located on Bingley Street -in two blocks."        },
      {
        img: "/images/proj5_kingsway.png",
        title: "Kingsway Buisness Park",
        client: "Wilson Bowden developments",
        location: "Greater Manchester",
        date: "September 2023",
        rating: "Very Good",
        area: 2677,
        type: "Industrial",
        scope: "Shell & Core",
      content:
      ""        
      },
      {
        img: "/images/proj7_childcenter.jpg",
        title: "Child Development Centre",
        client: "NHS Crawley",
        location: "Crawley",
        date: "September 2023",
        rating: "Very Good",
        area: 2677,
        type: "Healthcare",
        scope: "Fully Fitted",
      content:
      "Part refurbishment of Yellow Wing to form Child Development Centre (CDC)"        
      },
      {
        img: "/images/proj10_artsfacility.jpg",
        title: "Arts Faculty Building",
        client: "NHS Crawley",
        location: "University of Nottingham",
        date: "2014",
        rating: "Excellent",
        area: 2677,
        type: "Education",
        scope: "Fully Fitted",
      content:
      ""        
      },
      {
        img: "/images/proj11_standish.jpg",
        title: "Standish Medical Centre",
        client: "NHS Crawley",
        location: "Lancashire",
        date: "2008",
        rating: "Excellent",
        area: 2677,
        type: "Healthcare",
        scope: "Fully Fitted",
      content:
      ""        
      }

        
    ];
    return(
        <main className="story-section md:px-[40px] px-[20px] md:py-[80xp] py-[60px] w-full" id="projects">
        {/* <section className="story-header flex items-center justify-center flex-col">
          <span className="mb-3 -badge">stories of success</span>
          <h2>our player stories</h2>
        </section> */}
        <span className="bg-green text-white p-2 rounded">Stories of Success </span>
        <h1>Our Projects</h1>
        
        <h1 className="text-left w-full font-normal translate-y-[2px] mt-10">Industrial</h1>
        <div className="h-[4px] w-full bg-green"/>
        <ProjectSwiper content={playerContent.filter(obj => {return(obj.type == "Industrial") } ) }/>

        <h1 className="text-left w-full font-normal translate-y-[2px] mt-10">Healthcare</h1>
        <div className="h-[4px] w-full bg-green"/>        
        <ProjectSwiper content={playerContent.filter(obj => {return(obj.type == "Healthcare") } ) }/>
          
        <h1 className="text-left w-full font-normal translate-y-[2px] mt-10">Residential</h1>
        <div className="h-[4px] w-full bg-green"/>        
        <ProjectSwiper content={playerContent.filter(obj => {return(obj.type == "Residential") } ) }/>

        <h1 className="text-left w-full font-normal translate-y-[2px] mt-10">Education</h1>
        <div className="h-[4px] w-full bg-green"/>        
        <ProjectSwiper content={playerContent.filter(obj => {return(obj.type == "Education") } ) }/>


        {/* SWIPER SECTION */}

      </main>

    )


}


const SwiperBtn = () => {
    const swiper = useSwiper();
  
    return (
      <div className="swiper-btns w-full flex items-center justify-end gap-x-4 mt-10 pe-8">
        <button className="text-white bg-green transition duration-300 hover:bg-black hover:text-white text-[15px] w-[50px] h-[40px] flex items-center justify-center skew-x-[-10deg]" onClick={() => swiper.slideNext()}>
          <FaArrowLeftLong />
        </button>
        <button className="text-white bg-green transition duration-300 hover:bg-black hover:text-white text-[15px] w-[50px] h-[40px] flex items-center justify-center skew-x-[-10deg]"  onClick={() => swiper.slidePrev()}>
          <FaArrowRightLong />
        </button>
      </div>
    );
  };