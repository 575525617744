import React from "react";
import { useState } from "react";

import './NavBar.css';

export default function NavBar({onNavClick}) {
    const [active, setActive] = useState(false);
    const [open, setOpen] = useState(false);
    const [activeLink, setActiveLink] = useState("home");
    const [scrollAnimation, setScrollAnimation] = useState(false);

    window.addEventListener("scroll", () => {
        window.scrollY > 50 ? setScrollAnimation(true) : setScrollAnimation(false);
      });

      const navClickFunc = (value) => {
        setActiveLink(value);
        setOpen(false);
        setActive(false);
        // alert("clicked")
        onNavClick(value);  // Use the passed function
    }

    // const navClickFunc = (value) => {
    //     setActiveLink(value);
    //     setOpen(false);
    //     setActive(false);
    //     window.scrollTo({
    //       top: 0,
    //     });
    // }

    const openSideBar = () => {
        setOpen(!open);
        setActive(!active);
      };

    const navItems = [
        "clients",
        "projects",
        "qualifications",
      ];

    return(
        <nav className={`navbar md:px-[100px] px-5 z-50 w-full flex items-center justify-between absolute top-0 left-0 ${scrollAnimation ? "nav-scroll shadow" : ""}`}>
        <svg className="h-[13vh] py-3 z-50" xmlns="http://www.w3.org/2333/svg" viewBox="0 0 136.32 51.84">
                <g id="Layer_1-2" data-name="Layer 1" >
                    <g>
                    {/* <path style={{fill: '#fff'}}class="cls-1" d="m136.32,27.7v24.14H0V0h136.32v22.68h-11.21v-6.52h9.87v-4.94h-9.88v-5.8h10.44V.57h-16.54v27.13h17.32ZM26.89.24c-7.34,0-14.69,0-22.03,0-.52,0-1.04.01-1.55.07C1.61.53.33,1.94.33,3.64c0,14.85-.02,29.71.03,44.56,0,.72.41,1.53.84,2.14.83,1.15,2.13,1.24,3.45,1.24,14.93,0,29.86,0,44.79,0,2.09,0,3.46-1.39,3.46-3.52,0-14.77,0-29.55,0-44.32,0-2.12-1.37-3.48-3.5-3.48-7.5,0-15.01,0-22.51,0Zm52.06,27.43V.56h-5.25v18.82s-.12-.1-.17-.23c-2.51-6.32-6.17-12.03-9.46-17.94-.16-.28-.46-.69-.7-.7-2.25-.05-4.51-.03-6.73-.03v27.11h5.37V7.73c.17.21.22.26.26.33,3.32,6.3,6.63,12.6,9.99,18.88.18.33.69.67,1.06.69,1.83.07,3.67.03,5.63.03ZM97.65.54v27.05h16.75v-5.02h-10.73V.54h-6.02Zm37.82,51c.01-.12.05-.2.03-.27-2.02-6.53-4.04-13.06-6.11-19.57-.1-.32-.63-.7-.98-.72-1.35-.08-2.71.03-4.07-.06-.99-.06-1.38.31-1.65,1.23-1.79,5.98-3.64,11.93-5.47,17.9-.14.47-.24.96-.38,1.53,1.37,0,2.61-.05,3.84.02.71.04.97-.25,1.13-.89.3-1.23.63-2.47,1.05-3.66.12-.32.61-.71.95-.73,1.43-.08,2.87,0,4.31-.05.76-.03,1.04.29,1.23.96.4,1.45.85,2.88,1.29,4.32h4.84Zm-69.37-4.77c-.11-.23-.19-.36-.22-.5-1.3-4.79-2.59-9.58-3.92-14.37-.1-.35-.45-.89-.72-.9-1.46-.09-2.93-.04-4.61-.04,2.24,6.99,4.42,13.78,6.61,20.62,1.55,0,3.02-.04,4.5.02.73.03,1.02-.24,1.25-.91,2.07-6.22,4.18-12.43,6.27-18.64.11-.31.16-.65.27-1.11-1.35,0-2.54.05-3.72-.02-.84-.05-1.17.29-1.39,1.07-1.39,4.88-2.83,9.75-4.3,14.78Zm25.08-19.17V.56h-5.97v27.04h5.97Zm.07,3.34c-1.19,0-2.23.06-3.25-.02-1.02-.07-1.38.24-1.37,1.32.05,6.05.02,12.1.03,18.15,0,.38.06.75.09,1.11h12.78c0-.98-.03-1.89,0-2.8.03-.72-.25-.97-.98-.96-1.95.03-3.91,0-5.86-.01-.46,0-.91-.06-1.45-.1v-16.69Zm15.83,16.78c0-2.95,0-5.69,0-8.43s0-5.47,0-8.35c-1.17,0-2.21.06-3.23-.02-1.1-.09-1.42.3-1.41,1.4.05,5.97.02,11.94.03,17.91,0,.42.05.84.08,1.28h12.69v-3.79h-8.15Zm-24.76,3.82v-20.6c-1.1,0-2.14.07-3.16-.02-1.12-.09-1.41.33-1.4,1.41.05,5.97.02,11.95.03,17.93,0,.42.05.83.08,1.28h4.45Z"/> */}
                    <path style={{fill: '#333'}} d="m136.32,27.7h-17.32V.57h16.54v4.85h-10.44v5.8h9.88v4.94h-9.87v6.52h11.21v5.02Z"/>
                    <path  style={{fill: '#333'}} d="m26.89.24c7.5,0,15.01,0,22.51,0,2.12,0,3.5,1.37,3.5,3.48,0,14.77,0,29.55,0,44.32,0,2.13-1.36,3.52-3.46,3.52-14.93,0-29.86,0-44.79,0-1.32,0-2.62-.09-3.45-1.24-.44-.61-.84-1.42-.84-2.14-.05-14.85-.04-29.71-.03-44.56,0-1.7,1.28-3.12,2.97-3.33.51-.06,1.03-.07,1.55-.07,7.34,0,14.69,0,22.03,0ZM6.29,10.21c-.27,1.5-.53,2.77-.73,4.06-.94,6-1.5,12.01-.35,18.06,1.07,5.61,3.32,10.55,8.11,14.01,4.57,3.3,9.81,4.47,15.35,4.59,1.67.03,1.98-.2,2.49-1.81,1.03-3.28,1.08-6.6.12-9.89-1.97-6.75-6.52-11.16-12.89-13.86-.36-.15-.69-.35-1.04-.52.06-.11.12-.23.17-.34,5.44.79,10.64,2.22,14.7,6.24,4.17,4.14,5.2,9.46,5.3,15.11.31-.43.49-.85.64-1.28.86-2.65,1.13-5.37.98-8.13-.28-5.13-2.24-9.49-6.23-12.81-3.49-2.9-7.66-4.56-11.79-6.25-5.01-2.05-10.24-3.62-14.81-7.17ZM40.07,1.13c-1.65,1.19-3.11,2.2-4.53,3.26-4.24,3.17-8.13,6.68-11.26,10.98-.77,1.05-.72,1.21.45,1.72.36.16.73.32,1.1.47,5.45,2.3,10.4,5.25,13.76,10.32.04.06.2.05.69.15-.89-2.77-1.72-5.33-2.49-7.72.33.48.77,1.12,1.21,1.76.09-.05.19-.1.28-.15-.37-.86-.75-1.72-1.12-2.57.09-.05.17-.09.26-.14.87,1.36,1.8,2.69,2.6,4.09,3.88,6.74,4.48,13.47.05,20.27-.72,1.1-.95,2.52-1.41,3.79.11.06.21.12.32.18.49-.33,1-.62,1.46-.98,6.24-4.93,9.4-11.18,7.41-19.14-1.02-4.1-2.81-8.02-4.27-12.01-1.36-3.73-2.79-7.44-4.11-11.18-.3-.86-.26-1.85-.41-3.07Z"/>
                    <path style={{fill: '#333'}} d="m97.65.54h6.02v22.03h10.73v5.02h-16.75V.54Z"/>
                    <path  style={{fill: '#333'}} d="m135.47,51.54h-4.84c-.43-1.44-.89-2.87-1.29-4.32-.18-.67-.47-.99-1.23-.96-1.43.06-2.87-.03-4.31.05-.34.02-.83.4-.95.73-.43,1.2-.75,2.43-1.05,3.66-.16.64-.41.93-1.13.89-1.23-.06-2.47-.02-3.84-.02.14-.57.23-1.06.38-1.53,1.82-5.96,3.68-11.92,5.47-17.9.28-.92.67-1.3,1.65-1.23,1.35.09,2.71-.03,4.07.06.35.02.88.4.98.72,2.07,6.51,4.08,13.04,6.11,19.57.02.07-.01.15-.03.27Zm-9.52-17.15c-.79,2.99-1.48,5.63-2.19,8.35h4.55c-.78-2.75-1.53-5.4-2.36-8.35Z"/>
                    <path  style={{fill: '#333'}} d="m66.1,46.77c1.47-5.02,2.91-9.89,4.3-14.78.22-.78.55-1.12,1.39-1.07,1.19.07,2.38.02,3.72.02-.11.46-.16.79-.27,1.11-2.09,6.21-4.2,12.42-6.27,18.64-.22.67-.52.94-1.25.91-1.47-.06-2.94-.02-4.5-.02-2.19-6.83-4.37-13.63-6.61-20.62,1.68,0,3.15-.05,4.61.04.26.02.62.55.72.9,1.33,4.78,2.62,9.58,3.92,14.37.04.14.12.27.22.5Z"/>
                    <path style={{fill: '#333'}} d="m91.18,27.6h-5.97V.56h5.97v27.04Z"/>
                    <path style={{fill: '#333'}} d="m91.25,30.94v16.69c.53.04.99.09,1.45.1,1.95.02,3.91.04,5.86.01.72-.01,1.01.24.98.96-.04.91,0,1.82,0,2.8h-12.78c-.03-.35-.09-.73-.09-1.11,0-6.05.02-12.1-.03-18.15,0-1.08.35-1.39,1.37-1.32,1.03.07,2.06.02,3.25.02Z"/>
                    <path style={{fill: '#333'}} d="m107.07,47.72h8.15v3.79h-12.69c-.03-.44-.08-.86-.08-1.28,0-5.97.02-11.94-.03-17.91,0-1.1.31-1.49,1.41-1.4,1.02.08,2.05.02,3.23.02,0,2.88,0,5.62,0,8.35s0,5.48,0,8.43Z"/>
                    <path style={{fill: '#333'}} d="m82.31,51.54h-4.45c-.03-.44-.08-.86-.08-1.28,0-5.98.02-11.95-.03-17.93,0-1.08.29-1.51,1.4-1.41,1.02.09,2.06.02,3.16.02v20.6Z"/>
                    <path style={{fill: '#333'}} d="m78.95,27.66c-1.96,0-3.8.04-5.63-.03-.37-.01-.88-.35-1.06-.69-3.35-6.28-6.66-12.58-9.99-18.88-.04-.07-.09-.12-.26-.33v19.86h-5.37V.49c2.22,0,4.47-.02,6.73.03.24,0,.54.41.7.7,3.29,5.91,6.95,11.62,9.46,17.94.05.13.17.24.17.23V.56h5.25v27.1Z"/>
                    {/* <path style={{fill: '#fff'}} class="cls-1" d="m6.29,10.21c4.57,3.56,9.81,5.12,14.81,7.17,4.13,1.69,8.3,3.35,11.79,6.25,4,3.32,5.95,7.68,6.23,12.81.15,2.76-.12,5.48-.98,8.13-.14.43-.33.85-.64,1.28-.1-5.65-1.13-10.97-5.3-15.11-4.06-4.03-9.26-5.46-14.7-6.24-.06.11-.12.23-.17.34.35.17.68.37,1.04.52,6.37,2.7,10.92,7.11,12.89,13.86.96,3.29.91,6.61-.12,9.89-.5,1.6-.82,1.84-2.49,1.81-5.54-.11-10.78-1.29-15.35-4.59-4.78-3.46-7.04-8.4-8.11-14.01-1.15-6.04-.59-12.05.35-18.06.2-1.28.46-2.56.73-4.06Z"/> */}
                    {/* <path style={{fill: '#fff'}} class="cls-1" d="m40.07,1.13c.15,1.23.11,2.21.41,3.07,1.31,3.75,2.74,7.45,4.11,11.18,1.46,3.99,3.25,7.91,4.27,12.01,1.98,7.97-1.17,14.22-7.41,19.14-.46.36-.97.66-1.46.98-.11-.06-.21-.12-.32-.18.46-1.27.7-2.68,1.41-3.79,4.43-6.8,3.82-13.53-.05-20.27-.8-1.4-1.73-2.73-2.6-4.09-.09.05-.17.09-.26.14.37.86.75,1.72,1.12,2.57-.09.05-.19.1-.28.15-.44-.64-.88-1.28-1.21-1.76.77,2.39,1.59,4.95,2.49,7.72-.49-.1-.65-.08-.69-.15-3.36-5.07-8.31-8.02-13.76-10.32-.37-.15-.73-.31-1.1-.47-1.17-.51-1.22-.67-.45-1.72,3.13-4.29,7.03-7.81,11.26-10.98,1.42-1.06,2.88-2.08,4.53-3.26Z"/> */}
                    {/* <path style={{fill: '#fff'}} class="cls-1" d="m125.95,34.39c.83,2.95,1.58,5.6,2.36,8.35h-4.55c.71-2.72,1.4-5.35,2.19-8.35Z"/> */}
                    </g>
                </g>
            </svg>
        <div className="logo py-3">
          {/* <img src="/images/nile_villa_logo_simple.png" className="h-[13vh] h-auto object-cover" alt="" /> */}
        </div>
        
        {/* LARGE SCREEN NAV-LINKS */}
        <div className="links_section hidden lg:flex items-center space-x-8">
          <ul className="list-none text-[35px] flex items-center space-x-6">
            {navItems.map((element, index) => {
              return (
                <li key={index} onClick={() => navClickFunc(element)} className="hover:text-green hover:cursor-pointer text-black transition duration-500">{element}</li>
              );
            })}
            <div className="bg-[#627E8B] text-white rounded px-[8px] py-[1px] hover:bg-white hover:text-black transition duration-500 hover:cursor-pointer" onClick={() => navClickFunc('contact')}>contact</div>

          </ul>
        </div>
        
        {/* ANIMATED HAMBURGER */}
        <div className="lg:hidden">
          <button type="button" className="hamburger flex flex-col gap-2 text-black" onClick={openSideBar}>
            <span className={`bg-black w-6 h-1 bg-gray-800 rounded-full transition duration-500 ${open ? "rotate-45 translate-y-1" : ""}`}></span>
            <span className={`bg-black w-6 h-1 bg-gray-800 rounded-full transition duration-500 ${open ? "opacity-0" : ""}`}></span>
            <span className={`bg-black w-6 h-1 bg-gray-800 rounded-full transition duration-500 ${open ? "-rotate-45 -translate-y-1" : ""}`}></span>
          </button>
        </div>
        
        {/* SMALL SCREEN NAV-LINKS */}
        <div className={`mobile_menu lg:hidden absolute top-20 bg-white shadow-lg w-11/12 z-50 transition-all duration-300 ${active ? "opacity-100" : "opacity-0 -top-full"}`}>
          <div className="w-full flex items-start justify-start flex-col pt-4">
            <ul className="list-none flex flex-col gap-4 w-full pb-[30px]">
              {navItems.map((element, index) => {
                return (
                  <li key={index}>{element}</li>
                );
              })}
            <li className="flex justify-center items-center rounded px-[8px] py-[1px] hover:bg-white hover:text-black transition duration-500 hover:cursor-pointer" onClick={() => navClickFunc('contact')}>contact</li>
            </ul>

          </div>
        </div>
      </nav>
      
    )
}